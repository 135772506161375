import React, { useState, useEffect } from 'react';
import useAxios from '../util/useAxios';

const Profile = () => {
  const [ownerInfo, setOwnerInfo] = useState({
    full_name: '',
    phone_number: '',
    aadhaar_front_image: null,
    aadhaar_back_image: null,
  });

  const [alertMessage, setAlertMessage] = useState(null);
  const api = useAxios();

  // Fetch existing owner information when the component mounts
  useEffect(() => {
    const fetchOwnerInformation = async () => {
      try {
        const response = await api.get('/api/create_owner_information/'); // Replace with the actual API endpoint
        if (response.status === 200) {
          setOwnerInfo(response.data);
        }
      } catch (error) {
        console.error('Error fetching owner information:', error);
      }
    };

    fetchOwnerInformation();
  }, [api]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      setOwnerInfo({
        ...ownerInfo,
        [name]: files[0],
      });
    } else {
      setOwnerInfo({
        ...ownerInfo,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('full_name', ownerInfo.full_name);
    formData.append('phone_number', ownerInfo.phone_number);
    formData.append('aadhaar_front_image', ownerInfo.aadhaar_front_image);
    formData.append('aadhaar_back_image', ownerInfo.aadhaar_back_image);

    try {
      const response = await api.post('/api/create_owner_information/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setAlertMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error creating/updating owner information:', error);
      setAlertMessage('Error creating/updating owner information. Please try again.');
    }
  };

  return (
    <div className='container'>
      <h1 className="mb-4">Owner Profile</h1>
      {alertMessage && (
        <div className={`alert ${alertMessage.includes('successfully') ? 'alert-success' : 'alert-danger'}`} role="alert">
          {alertMessage}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="full_name" className="form-label">
            Full Name
          </label>
          <input
            type="text"
            className="form-control"
            id="full_name"
            name="full_name"
            value={ownerInfo.full_name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phone_number" className="form-label">
            Phone Number
          </label>
          <input
            type="text"
            className="form-control"
            id="phone_number"
            name="phone_number"
            value={ownerInfo.phone_number}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="aadhaar_front_image" className="form-label">
            Aadhaar Front Image
          </label>
          <input
            type="file"
            className="form-control"
            id="aadhaar_front_image"
            name="aadhaar_front_image"
            accept="image/*"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="aadhaar_back_image" className="form-label">
            Aadhaar Back Image
          </label>
          <input
            type="file"
            className="form-control"
            id="aadhaar_back_image"
            name="aadhaar_back_image"
            accept="image/*"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
