import React, { useEffect, useState } from "react";
import useAxios from "../util/useAxios";
import { Link } from 'react-router-dom';

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
 
  const api = useAxios();

  useEffect(() => {
    getTenant();
   
  }, []);

  const getTenant = async () => {
    try {
      const response = await api.get("/api/tenants/");

      if (response.status === 200) {
        setTenants(response.data);
      }
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
  };


  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h1>Tenants List</h1>
        <Link to="add"><button className="btn btn-primary">Add Tenant</button></Link>
      </div>
      <table className="table table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>Name</th>
            <th>Contact Number</th>
            <th>Move-in Date</th>
            <th>Rent Amount</th>
            <th>Sharing Type</th>
            <th>Room Number</th>
            <th>Deposit Paid</th>
            <th>Deposit Amount</th>
            <th>Rent Payable</th>
            <th>Rent Paid</th>
            <th>Payment Date</th>
            <th>Edit</th>
            <th>Delete</th>
            
          </tr>
        </thead>
        <tbody>
          {tenants.map((tenant) => (
            <tr key={tenant.id}>
              <td>{tenant.tenant_name}</td>
              <td>{tenant.contact_number}</td>
              <td>{tenant.move_in_date}</td>
              <td>{tenant.rent_amount}</td>
              <td>{tenant.sharing_type}</td>
              <td>{tenant.room_number}</td>
              <td>{tenant.deposit_paid ? "Yes" : "No"}</td>
              <td>{tenant.deposit_amount}</td>
              <td>{tenant.rent_payable}</td>
              <td>{tenant.rent_paid ? "Yes" : "No"}</td>
              <td>{tenant.payment_date}</td>
              <td><button className="btn btn-warning">Edit</button></td>
              <td><button className="btn btn-danger">Delete</button></td>

              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tenants;
