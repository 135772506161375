import React, { useState, useEffect } from "react";
import useAxios from "../util/useAxios";

const AddTenants = () => {
  const [formData, setFormData] = useState({
    tenant_name: "",
    contact_number: "",
    rent_amount: "",
    move_in_date: "",
    sharing_type: "",
    room_number: "",
    deposit_paid: false,
    deposit_amount: "",
    rent_payable: "",
    rent_paid: false,
    payment_date: "",
    pg: "",
  });

  const [pgs, setPgs] = useState([]); // State to store the list of PGs
  const [isSuccess, setIsSuccess] = useState(false); // State to manage success alert

  const api = useAxios();

  useEffect(() => {
    // Fetch the list of PGs when the component mounts
    api
      .get("/getmypgs/") // Replace with your PGs API endpoint
      .then((response) => {
        setPgs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching PGs:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/api/tenants/", formData);

      if (response.status === 201) {
        console.log("Tenant added successfully");
        setIsSuccess(true); // Set success state to true
        setTimeout(() => {
          setIsSuccess(false); // Clear success alert after a few seconds
        }, 3000);
        // Clear form fields after successful submission
        setFormData({
          tenant_name: "",
          contact_number: "",
          rent_amount: "",
          move_in_date: "",
          sharing_type: "",
          room_number: "",
          deposit_paid: false,
          deposit_amount: "",
          rent_payable: "",
          rent_paid: false,
          payment_date: "",
          pg: "",
        });
      }
    } catch (error) {
      console.error("Error adding tenant:", error);
    }
  };

  return (
    <div className="container mt-4">
      <h1>Add Tenant</h1>
      {isSuccess && (
        <div className="alert alert-success" role="alert">
          Tenant added successfully!
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Select PG:</label>
              <select
                name="pg"
                value={formData.pg}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">Select a PG</option>
                {pgs.map((pg) => (
                  <option key={pg.id} value={pg.id}>
                    {pg.product_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                name="tenant_name"
                value={formData.tenant_name}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Contact Number:</label>
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Rent Amount:</label>
              <input
                type="number"
                name="rent_amount"
                value={formData.rent_amount}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Move-in Date:</label>
              <input
                type="date"
                name="move_in_date"
                value={formData.move_in_date}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Sharing Type:</label>
              <input
                type="number"
                name="sharing_type"
                value={formData.sharing_type}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Room Number:</label>
              <input
                type="text"
                name="room_number"
                value={formData.room_number}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Deposit Paid:</label>
              <input
                type="checkbox"
                name="deposit_paid"
                checked={formData.deposit_paid}
                onChange={handleChange}
                className="form-check-input"
              />
            </div>
            <div className="form-group">
              <label>Deposit Amount:</label>
              <input
                type="number"
                name="deposit_amount"
                value={formData.deposit_amount}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Rent Payable:</label>
              <input
                type="number"
                name="rent_payable"
                value={formData.rent_payable}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Rent Paid:</label>
              <input
                type="checkbox"
                name="rent_paid"
                checked={formData.rent_paid}
                onChange={handleChange}
                className="form-check-input"
              />
            </div>
            <div className="form-group">
              <label>Payment Date:</label>
              <input
                type="date"
                name="payment_date"
                value={formData.payment_date}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary mt-2 text-center">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddTenants;
