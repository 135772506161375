import React, { useEffect, useState } from 'react';
import useAxios from '../util/useAxios';
import { useParams } from 'react-router-dom';

const ProductDetail = () => {
  const { id } = useParams(); // Get the 'id' param from the URL
  const [product, setProduct] = useState(null); // Use 'product' instead of 'products'
  const api = useAxios();

  useEffect(() => {
    getSingleProduct(); // Fetch the details of a single product
  }, [id]);

  const getSingleProduct = async () => {
    try {
      const response = await api.get(`/getmypgs/${id}/`); // Use the 'id' from params

      if (response.status === 200) {
        setProduct(response.data); // Set 'product' to the fetched data
        console.log('Product Details:', response.data);
      }
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  return (
    <div className="container mt-5">
      {product ? (
        <>
          <h2 className="mb-3">Pg Name: {product.product_name}</h2>
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>City:</strong> {product.city}
              </p>
              <p>
                <strong>Locality:</strong> {product.locality}
              </p>
              <p>
                <strong>Best Suited For:</strong> {product.best_suited_for}
              </p>

              <h3>Common Areas:</h3>
              <ul>
                {product.common_areas.map((area, index) => (
                  <li key={index}>{area}</li>
                ))}
              </ul>

              <h3>Furnishing in Property:</h3>
              <ul>
                {product.furnishing_in_property.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>

              <h3>Security Amenities:</h3>
              <ul>
                {product.security_amenities.map((amenity, index) => (
                  <li key={index}>{amenity}</li>
                ))}
              </ul>
            </div>
            <div className="col-md-6">
              <h3>Services in Property:</h3>
              <ul>
                {product.services_in_property.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>

              <p>
                <strong>Phone Number:</strong> {product.phone_number}
              </p>
              <p>
                <strong>Property Managed By:</strong> {product.property_managed_by}
              </p>
              <p>
                <strong>Property Manager Stays:</strong>{' '}
                {product.property_manager_stays ? 'Yes' : 'No'}
              </p>
              <p>
                <strong>Meals Available:</strong>{' '}
                {product.meals_available ? 'Yes' : 'No'}
              </p>
              <p>
                <strong>Total Beds:</strong> {product.total_beds}
              </p>
              <p>
                <strong>Minimum Price:</strong> ₹{product.min_price}
              </p>
              <p>
                <strong>Double Sharing Deposit:</strong> ₹{product.double_sharing_deposite}
              </p>
              <p>
                <strong>Double Sharing Price:</strong> ₹{product.double_sharing_price}
              </p>
              <p>
                <strong>Single Sharing Deposit:</strong> ₹{product.single_sharing_deposite}
              </p>
              <p>
                <strong>Single Sharing Price:</strong> ₹{product.single_sharing_price}
              </p>
              <p>
                <strong>Triple Sharing Price:</strong> ₹{product.triple_sharing_price}
              </p>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ProductDetail;
