import React, { useState, useEffect, useContext } from 'react';
import useAxios from './../util/useAxios';
import AuthContext from './../context/AuthContext';
import { Link } from 'react-router-dom';

const Home = () => {
  const [notes, setNotes] = useState([]);
  const [products, setProducts] = useState([]);
  const [ownerInfo, setOwnerInfo] = useState(null); // State to store owner information
  const { authTokens, logoutUser } = useContext(AuthContext);

  const api = useAxios();

  useEffect(() => {
    getNotes();
    getProducts();
    getOwnerInformation(); // Fetch owner information when the component mounts
  }, []);

  const getNotes = async () => {
    try {
      const response = await api.get('/api/notes/');

      if (response.status === 200) {
        setNotes(response.data);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const getProducts = async () => {
    try {
      const response = await api.get('/getmypgs/');

      if (response.status === 200) {
        setProducts(response.data);
        console.log('Products:', response.data);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const getOwnerInformation = async () => {
    try {
      const response = await api.get('/api/create_owner_information/');

      if (response.status === 200) {
        setOwnerInfo(response.data);
      }
    } catch (error) {
      console.error('Error fetching owner information:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <h1>Welcome to Your Dashboard</h1>
          <p>You are logged in to the home page!</p>

          <h2>Notice from PopularPg-admin:</h2>
          <ul className="list-group">
            {notes.map((note) => (
              <li key={note.id} className="list-group-item">
                {note.body}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-6">
          <h2>Your PGs:</h2>
          <ul className="list-group">
            {products.map((product) => (
              <li key={product.id} className="list-group-item">
                {/* Wrap the product name with a Link */}
                <Link to={`/product/${product.id}`}>{product.product_name}</Link>
              </li>
            ))}
          </ul>

          {/* Conditional rendering of owner information or button */}
          {ownerInfo ? (
            <div>
              <h2>Your Owner Information:</h2>
              <p>Full Name: {ownerInfo.full_name}</p>
              <p>Phone Number: {ownerInfo.phone_number}</p>
              {/* Display other owner information */}
            </div>
          ) : (
            <div>
              <p>No owner information found.</p>
              <Link to="/profile" className="btn btn-primary">
                Create Owner Information
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
