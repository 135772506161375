import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Header from "./components/Header";

import PrivateRoutes from "./util/PrivateRoutes";
import ProductDetail from "./pages/ProductDetail";
import Profile from './pages/Profile';
import Tenants from "./pages/Tenants";
import AddTenants from "./pages/AddTenants";


function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/tenants" element={<Tenants />} />
          <Route exact path="/tenants/add" element={<AddTenants />} />
          <Route path="/product/:id" element={<ProductDetail />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
